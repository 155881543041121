import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import moment from 'moment';
import uuid from 'uuid';
import {Map, Set} from 'immutable';
import styled from 'styled-components';
import {Code} from 'react-content-loader'
import Sidebar from 'arui-feather/sidebar'
import Spin from 'arui-feather/spin'
import axios from 'axios'
import ls from 'local-storage'

export default function AuthPanel(props) {
    const [email, setEmail] = useState(ls('zz_email') == undefined ? '' : ls('zz_email'));
    console.log('AuthPanel: render: email ', email);

    useEffect(() => {
        ls('zz_email', email);
    }, [email]);

    return (
        <Wrapper>
            <Input value={email} placeholder={'Email'} onChange={evt => {
                setEmail(evt.target.value.replace(/ /g, '').toLowerCase());
            }}/>
        </Wrapper>
    );
}

const Wrapper = styled.div`
    
`;

const Input = styled.input`
    box-sizing: border-box;
    width: 100%;
    margin-right: 20px;
    height: 40px;
    padding-left: 4px;
`;
