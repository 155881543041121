import axios from 'axios'

const API_ENDPOINT = `https://api.ru.yt.englishpatient.org`;

const SearchAPI = {

    async findItems(text) {
        let items = (await axios.get(`${API_ENDPOINT}/youtube/captions/find`, {
            params: {
                q: text
            }
        })).data.result;
        return items;
    },


    async getAllYoutubeVideos() {
        let items = (await axios.get(`${API_ENDPOINT}/youtube/all/short`)).data.result;
        return items;
    },

    async addVideoToLibrary(videoId) {
        await axios.get(`${API_ENDPOINT}/youtube/${videoId}/captions/lazy`)
    },

    async getAllMaterials() {
        let arr = (await axios.get(`${API_ENDPOINT}/materials`)).data.result;
        return arr;
    },

    async getMaterialsByWord(word) {
        let arr = (await axios.get(`${API_ENDPOINT}/word_materials`, {
            params: {
                word: word
            }
        })).data.result;
        return arr;
    },


    async saveMaterial(d) {
        let pld = (await axios.post(`${API_ENDPOINT}/materials`, {
            ...d
        })).data.result;
        return pld;
    },

    async deleteMaterial(id) {
        let pld = (await axios.delete(`${API_ENDPOINT}/materials`, {
            data: {id: id}
        })).data.result;
        return pld;
    },

}

export default SearchAPI;
