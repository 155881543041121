import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import moment from 'moment';
import uuid from 'uuid';
import {Map, Set} from 'immutable';
import styled from 'styled-components';
import {Code} from 'react-content-loader'
import Sidebar from 'arui-feather/sidebar'
import Spin from 'arui-feather/spin'
import axios from 'axios'
import SearchAPI from "../../api/SearchAPI";


export default function AdminPanel(props) {
    const [items, setItems] = useState([]);

    const [text, setText] = useState('');

    useEffect(() => {
        SearchAPI.getAllYoutubeVideos().then(x => {
            setItems(x);
        });
    }, []);

    return (
        <Wrapper>

            <TopPlaceholder>
                <InputPlaceholder>
                    <Input value={text}
                           placeholder={'youtube url'}
                           onChange={evt => {
                               setText(evt.target.value.replace(/ /g, ''));
                           }}/>
                </InputPlaceholder>
                <Button onClick={async () => {
                    let vId = youtube_parser(text);
                    if (vId == false) {
                        return;
                    }
                    await SearchAPI.addVideoToLibrary(vId);
                    let arr = await SearchAPI.getAllYoutubeVideos();
                    setItems(arr);
                }}>
                    Добавить видео
                </Button>
            </TopPlaceholder>

            <ItemsPlaceholder>
                {items.map((a, i) => {
                    return (
                        <Item key={a.videoId}>
                            <ItemName>
                                <a href={`https://www.youtube.com/watch?v=${a.videoId}`} target={'_blank'}>
                                    {`https://www.youtube.com/watch?v=${a.videoId}`}
                                </a>
                            </ItemName>
                        </Item>
                    )
                })}
            </ItemsPlaceholder>

        </Wrapper>
    );
}

function youtube_parser(url) {
    var regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
    var match = url.match(regExp);
    return (match && match[7].length == 11) ? match[7] : false;
}

const Wrapper = styled.div`
    width: 1080px;
    margin: 0 auto;
    box-sizing: border-box;
    padding-top: 10px;
    @media(max-width: 1080px){
      width: 100%;
    }
`;

const TopPlaceholder = styled.div`
    margin-bottom: 20px;
    padding-bottom: 5px;
    border-bottom: 1px solid lightgrey;
    display: flex;
    flex-direction: row;
    align-items: center;
`;

const InputPlaceholder = styled.div`
    flex: 1;
    margin-right: 20px;
`;

const Input = styled.input`
    box-sizing: border-box;
    width: 100%;
    margin-right: 20px;
    height: 40px;
    padding-left: 4px;
`;

const Button = styled.div`
    height: 50px;
    border-radius: 4px;
    background: blue;
    color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    padding-left: 25px;
    padding-right: 25px;
    :hover{
      opacity: 0.8;
    }
`;
const ItemsPlaceholder = styled.div`
    
`;

const Item = styled.div`
    padding: 10px;
    border-bottom: 1px solid lightgrey;
`;

const ItemName = styled.div`
    
`;
