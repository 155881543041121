import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import styled from 'styled-components';
import SearchAPI from "../../api/SearchAPI";
import NicePlayer from "./tools/NicePlayer";

import Sidebar from 'arui-feather/sidebar'


export default function MaterialsPanel(props) {
    const [materials, setMaterials] = useState([]);
    const [selectedId, setSelectedId] = useState(undefined);

    useEffect(() => {
        SearchAPI.getAllMaterials().then(arr => {
            setMaterials(arr);
        })
    }, []);

    const selectedItem = (selectedId == undefined) ? undefined : materials.filter(a => (a.id == selectedId))[0];

    return (
        <Wrapper>

            <MaterialsList>
                {materials.map((a, i) => {
                    let {word, transcript, enTranscript, chTranscript, userEmail} = a;
                    return (
                        <MaterialItem selected={(a.id == selectedId)} key={a.id} onClick={() => {
                            setSelectedId(a.id);
                        }}>
                            <MaterialLabel>
                                {word}
                            </MaterialLabel>
                            <MaterialTranscript>
                                {transcript}
                            </MaterialTranscript>
                            {(enTranscript == undefined || enTranscript == '') ? null :
                                <MaterialTranscript>
                                    {enTranscript}
                                </MaterialTranscript>
                            }
                            {(chTranscript == undefined || chTranscript == '') ? null :
                                <MaterialTranscript>
                                    {chTranscript}
                                </MaterialTranscript>
                            }
                            {userEmail == undefined || userEmail == '' ? null :
                                <UserEmailPlaceholder>
                                    {`${userEmail}`}
                                </UserEmailPlaceholder>
                            }

                        </MaterialItem>
                    )
                })}
            </MaterialsList>

            <div>
                {`${materials.length} materials`}
            </div>

            <Sidebar visible={(selectedItem != undefined)}
                     width={680}
                     onCloserClick={() => {
                         setSelectedId(undefined);
                     }}>

                {selectedItem == undefined ? null :
                    <div>
                        <h3>
                            {selectedItem.word}
                        </h3>
                        <p>
                            {selectedItem.transcript}
                        </p>
                        <div style={{width: 600, height: 400}}>
                            <NicePlayer {...selectedItem} />
                        </div>

                        <div style={{marginTop: 20}}>
                            <span style={{color: 'red', cursor: 'pointer'}} onClick={async () => {
                                if (window.confirm('Вы уверены?') == false) {
                                    return;
                                }
                                await SearchAPI.deleteMaterial(selectedItem.id);
                                setSelectedId(undefined);
                                let arr = await SearchAPI.getAllMaterials();
                                setMaterials(arr);
                            }}>
                                удалить
                            </span>
                        </div>
                    </div>
                }

            </Sidebar>

        </Wrapper>
    );
}

const UserEmailPlaceholder = styled.div`
    text-align: right;
    font-size: 12px;
    opacity: 0.8;
    font-style: italic;
`;

const Wrapper = styled.div`
    width: 1080px;
    box-sizing: border-box;
    margin: 0 auto;
    @media(max-width: 1080px){
      width: 100%;
    }  
`;

const MaterialLabel = styled.div`
    font-size: 20px;
    font-weight: bold;
`;

const MaterialTranscript = styled.div`
    
`;

const EnTranscript = styled.div`
    margin-top: 10px;
`;

const MaterialsList = styled.div`
    
`;

const MaterialItem = styled.div`
    background: ${props => (props.selected == true ? 'blue' : 'white')};
    color: ${props => (props.selected == true ? 'white' : 'black')};
    padding: 10px;
    border-radius: 4px;
    border: 1px solid lightgrey;
    margin-bottom: 10px;
    cursor: pointer;
    :hover{
      opacity: 0.8;
    }
`;
