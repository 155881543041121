import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import styled from 'styled-components';
import AdminPanel from "../search/AdminPanel";
import SearchPanel from "../search/SearchPanel";
import MaterialsPanel from "../search/MaterialsPanel";
import AuthPanel from "../search/AuthPanel";

const ITEMS = [
    {
        label: 'Поиск',
        value: 'search'
    },
    {
        label: 'Материалы',
        value: 'materials'
    },
    {
        label: 'Админка',
        value: 'admin'
    },
    {
        label: 'Авторизация',
        value: 'auth'
    },

]

export default function DashboardPanel(props) {
    const [tab, setTab] = useState('search');

    return (
        <Wrapper>

            <TabsPlaceholder>

                {ITEMS.map((a, i) => {
                    return (
                        <TabItem selected={(tab == a.value)} key={a.value} onClick={() => {
                            setTab(a.value);
                        }}>
                            {a.label}
                        </TabItem>
                    )
                })}

            </TabsPlaceholder>

            <ContentPlaceholder key={tab}>

                {tab != 'admin' ? null :
                    <AdminPanel/>
                }

                {tab != 'search' ? null :
                    <SearchPanel/>
                }

                {tab != 'materials' ? null :
                    <MaterialsPanel/>
                }

                {tab != 'auth' ? null :
                    <AuthPanel/>
                }



            </ContentPlaceholder>

        </Wrapper>
    );
}

const Wrapper = styled.div`
    width: 1020px;
    box-sizing: border-box;
    margin: 0 auto;
    @media(max-width: 1020px){
      width: 100%;
    }
`;

const ContentPlaceholder = styled.div`
    
`;

const TabsPlaceholder = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding-bottom: 10px;
    margin-bottom: 10px;
    margin-top: 20px;
`;

const TabItem = styled.div`
    margin-left: 10px;
    margin-right: 10px;
    cursor: pointer;
    border-bottom: ${props => (props.selected == true ? '2px solid blue' : '2px solid transparent')};
`;
