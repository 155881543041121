import React from 'react';
import logo from './logo.svg';
import './App.css';

import styled from 'styled-components'
import SearchPanel from "./components/search/SearchPanel";
import AdminPanel from "./components/search/AdminPanel";
import DashboardPanel from "./components/dashboard/DashboardPanel";

const isAdmin = (window.location.href.indexOf('admin') > -1);

function App() {
    return (
        <Wrapper>

            <DashboardPanel/>

        </Wrapper>
    );
}

export default App;

const Wrapper = styled.div`
    width: 100vw;
    
`;
