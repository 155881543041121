import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import moment from 'moment';
import uuid from 'uuid';
import {Map, Set} from 'immutable';
import styled from 'styled-components';
import {Code} from 'react-content-loader'
import Sidebar from 'arui-feather/sidebar'
import Spin from 'arui-feather/spin'
import axios from 'axios'

export default function UpdateFromToTool(props) {
    const {
        from,
        to,
        delta = 0.25,
        onChange = d => {

        }
    } = props;

    return (
        <Wrapper>

            <Left>
                <ControlButton onClick={() => {
                    let d = {
                        to: +to,
                        from: +from - delta
                    };
                    onChange(d);
                }}>
                    {'<'}
                </ControlButton>
                <Value>
                    {(+from).toFixed(2)}
                </Value>
                <ControlButton onClick={() => {
                    let d = {
                        to: +to,
                        from: +from + delta
                    };
                    onChange(d);
                }}>
                    {'>'}
                </ControlButton>
            </Left>

            <Right>
                <ControlButton onClick={() => {
                    let d = {
                        from: +from,
                        to: +to - delta
                    };
                    onChange(d);
                }}>
                    {'<'}
                </ControlButton>
                <Value>
                    {(+to).toFixed(2)}
                </Value>
                <ControlButton onClick={() => {
                    let d = {
                        from: +from,
                        to: +to + delta
                    };
                    onChange(d);
                }}>
                    {'>'}
                </ControlButton>
            </Right>

        </Wrapper>
    );
}

const Wrapper = styled.div`
    margin-top: 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
`;

const Value = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 80px;
`;

const ControlButton = styled.div`
    cursor: pointer;
    border: 1px solid lightgrey;
    border-radius: 4px;
    height: 24px;
    width: 24px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`;

const Left = styled.div`
    margin-right: 30px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
`;

const Right = styled.div`
    margin-left: 30px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
`;
