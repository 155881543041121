import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import moment from 'moment';
import uuid from 'uuid';
import {Map, Set} from 'immutable';
import styled from 'styled-components';
import {Code} from 'react-content-loader'
import Sidebar from 'arui-feather/sidebar'
import Spin from 'arui-feather/spin'
import axios from 'axios'

import SearchAPI from '../../api/SearchAPI'

import ReactPlayer from 'react-player'
import ReactHelper from "../../helpers/ReactHelper";
import UpdateFromToTool from "./tools/UpdateFromToTool";

import ls from 'local-storage'


export default function SearchPanel(props) {
    const [text, setText] = useState('');
    const [loading, setLoading] = useState(false);
    const [results, setResults] = useState([]);

    const [materials, setMaterials] = useState([]);

    const [transcript, setTranscript] = useState('');
    const [word, setWord] = useState('');
    const [saving, setSaving] = useState(false);
    const [saved, setSaved] = useState(true);

    const [searched, setSearched] = useState(false);

    const [bounds, setBounds] = useState({
        from: 0,
        to: 5
    });

    const playerRef = useRef();

    const [selectedId, setSelectedId] = useState(undefined);
    let selectedItem = results.filter(a => (`${a.videoId}_${a.start}` == selectedId))[0];


    ReactHelper.useInterval(() => {
        try {
            // window.currVideoDuration = videoRef.current.duration;
            let currTime = playerRef.current.getCurrentTime();
            // console.log('currTime = ', currTime);
            // console.log('bounds = ', bounds);
            if (+currTime < +bounds.from) {
                playerRef.current.seekTo(+bounds.from, 'seconds');
            }
            if (+currTime > +bounds.to) {
                playerRef.current.seekTo(+bounds.from, 'seconds');
            }
        } catch (e) {

        }
    }, 100);

    useEffect(() => {
        try {
            playerRef.current.seekTo(+bounds.from, 'seconds');
        } catch (e) {

        }
    }, [bounds.from]);

    useEffect(() => {
        try {
            playerRef.current.seekTo(+bounds.to - 0.5, 'seconds');
        } catch (e) {

        }
    }, [bounds.to]);

    console.log('render: results = ', results);

    return (
        <Wrapper>

            <TopPlaceholder>
                <InputPlaceholder>
                    <Input value={text}
                           placeholder={'Поиск'}
                           onChange={evt => {
                               setText(evt.target.value.replace(/ /g, ''));
                           }}/>
                </InputPlaceholder>
                <Button onClick={async () => {
                    setLoading(true);
                    setSearched(false);
                    setResults([]);
                    // setMaterials([]);
                    setSelectedId(undefined);
                    let arr = await SearchAPI.findItems(text);
                    console.log('found items: arr = ', arr);
                    setResults(arr);
                    setLoading(false);
                    setSearched(true);
                    let mats = await SearchAPI.getMaterialsByWord(text);
                    console.log('mats = ', mats);
                    setMaterials(mats);
                }}>
                    Поиск
                </Button>
            </TopPlaceholder>


            {loading == true ? <Code/> :
                <ContentPlaceholder>

                    <ItemsPlaceholder>

                        {materials.length == 0 ? null :
                            <div style={{marginBottom: 10, color: 'orange'}}>
                                {searched == false ? null : `по данному слову уже нарезано найдено ${materials.length} материалов`}
                            </div>
                        }

                        {results.map((a, i) => {
                            let id = `${a.videoId}_${a.start}`;
                            let from = +a.start;
                            let to = +a.start + +a.dur;
                            return (
                                <Item key={`${i}_${a.videoId}_${a.start}`}
                                      selected={(selectedId == id)}
                                      onClick={() => {
                                          setSelectedId(id);
                                          setBounds({
                                              from: from,
                                              to: to
                                          });
                                          setWord(text);
                                          setTranscript(a.text);
                                      }}>
                                    <ItemText>
                                        {a.text}
                                    </ItemText>
                                </Item>
                            )
                        })}
                    </ItemsPlaceholder>

                    {(results != undefined && results.length == 0 && searched == true) ?
                        <div>ничего не найдено</div> : null}

                    {selectedItem == undefined ? null :
                        <RightPlaceholder>

                            <CurrentVideoPlaceholder key={selectedItem.videoId}>
                                <ReactPlayer
                                    playing={true}
                                    width={'100%'}
                                    height={'100%'}
                                    url={`https://www.youtube.com/watch?v=${selectedItem.videoId}`}
                                    ref={playerRef}
                                />
                            </CurrentVideoPlaceholder>
                            <UpdateFromToTool {...bounds} onChange={d => {
                                setBounds(d);
                            }}/>

                            <div style={{marginBottom: 10, marginTop: 10}}>
                                <Input value={word} placeholder={'Слово'} onChange={evt => {
                                    setWord(evt.target.value);
                                }}/>
                            </div>

                            <div>
                                <Textarea placeholder={'Текст'} value={transcript} onChange={evt => {
                                    setTranscript(evt.target.value);
                                }}/>
                            </div>

                            <div style={{marinTop: 10}}>
                                {saving == true ? <Spin visible={true}/> :
                                    <Button onClick={async () => {
                                        setSaving(true);
                                        await SearchAPI.saveMaterial({
                                            videoId: selectedItem.videoId,
                                            ...bounds,
                                            word: word,
                                            transcript: transcript,
                                            userEmail: ls('zz_email')
                                        });
                                        setSaving(false);
                                    }}>
                                        Сохранить
                                    </Button>
                                }

                            </div>

                        </RightPlaceholder>
                    }

                </ContentPlaceholder>
            }

        </Wrapper>
    );
}

const Textarea = styled.textarea`
    width: 100%;
    box-sizing: border-box;
    min-height: 6em;
`;

const Wrapper = styled.div`
    width: 1080px;
    margin: 0 auto;
    box-sizing: border-box;
    padding-top: 10px;
    @media(max-width: 1080px){
      width: 100%;
    }
`;


const ContentPlaceholder = styled.div`
    display: flex;
    flex-direction: row;
    align-items: flex-start;
`;

const ItemsPlaceholder = styled.div`
    flex: 1;
    margin-right: 20px;
    max-height: calc(100vh - 200px);
    overflow-y: auto;
`;

const RightPlaceholder = styled.div`
    width: 500px;
`;

const CurrentVideoPlaceholder = styled.div`
    width: 500px;
    height: 360px;
`;

const ItemText = styled.div`
    
`;

const Item = styled.div`
    margin-bottom: 20px;
    padding: 10px;
    border-radius: 10px;
    border: 1px solid whitesmoke;
    background: ${props => (props.selected == true ? 'blue' : 'white')};
    color: ${props => (props.selected == true ? 'white' : 'black')};
    cursor: ${props => (props.selected == true ? 'default' : 'pointer')};
    :hover{
      opacity: ${props => (props.selected ? 1 : 0.7)};
    }
`;

const TopPlaceholder = styled.div`
    margin-bottom: 20px;
    padding-bottom: 5px;
    border-bottom: 1px solid lightgrey;
    display: flex;
    flex-direction: row;
    align-items: center;
`;

const InputPlaceholder = styled.div`
    flex: 1;
    margin-right: 20px;
`;

const Input = styled.input`
    box-sizing: border-box;
    width: 100%;
    margin-right: 20px;
    height: 40px;
    padding-left: 4px;
`;

const Button = styled.div`
    height: 50px;
    border-radius: 4px;
    background: blue;
    color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    padding-left: 25px;
    padding-right: 25px;
    :hover{
      opacity: 0.8;
    }
`;
