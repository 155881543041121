import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import styled from 'styled-components';
import ReactPlayer from 'react-player'
import ReactHelper from "../../../helpers/ReactHelper";

export default function NicePlayer(props) {
    const {videoId, from, to} = props;

    const playerRef = useRef();

    ReactHelper.useInterval(() => {
        try {
            let currTime = playerRef.current.getCurrentTime();
            if (+currTime < +from) {
                playerRef.current.seekTo(+from, 'seconds');
            }
            if (+currTime > +to) {
                playerRef.current.seekTo(+from, 'seconds');
            }
        } catch (e) {

        }
    }, 100);


    return (
        <Wrapper>

            <ReactPlayer
                playing={true}
                width={'100%'}
                height={'100%'}
                url={`https://www.youtube.com/watch?v=${videoId}`}
                ref={playerRef}
            />

        </Wrapper>
    );
}

const Wrapper = styled.div`
    width: 100%;
    height: 100%;
`;


const ContentPlaceholder = styled.div`
    display: flex;
    flex-direction: row;
    align-items: flex-start;
`;

const ItemsPlaceholder = styled.div`
    flex: 1;
`;

const RightPlaceholder = styled.div`
    width: 500px;
`;

const CurrentVideoPlaceholder = styled.div`
    width: 500px;
    height: 360px;
`;

const ItemText = styled.div`
    
`;

const Item = styled.div`
    padding: 10px;
    border-radius: 10px;
    border: 1px solid whitesmoke;
    background: ${props => (props.selected == true ? 'blue' : 'white')};
    color: ${props => (props.selected == true ? 'white' : 'black')};
    cursor: ${props => (props.selected == true ? 'default' : 'pointer')};
    :hover{
      opacity: ${props => (props.selected ? 1 : 0.7)};
    }
`;

const TopPlaceholder = styled.div`
    margin-bottom: 20px;
    padding-bottom: 5px;
    border-bottom: 1px solid lightgrey;
    display: flex;
    flex-direction: row;
    align-items: center;
`;

const InputPlaceholder = styled.div`
    flex: 1;
    margin-right: 20px;
`;

const Input = styled.input`
    box-sizing: border-box;
    width: 100%;
    margin-right: 20px;
    height: 40px;
    padding-left: 4px;
`;

const Button = styled.div`
    height: 50px;
    border-radius: 4px;
    background: blue;
    color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    padding-left: 25px;
    padding-right: 25px;
    :hover{
      opacity: 0.8;
    }
`;
